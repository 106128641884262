body {
	.buorg {
		@extend .shadow;
		position: absolute;
		position: fixed;
		z-index: map-get($z-index-map, 'browser_update');
		width: 100%;
		top: 0px;
		left: 0px;
		text-align: center;
		color: $white;
		border-bottom: none;
		background-color: $primary;
		font-family: $font-family-sans-serif;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
		animation: 1s ease-out 0s buorgfly;
	}

	.buorg-pad {
		padding: 9px;
		line-height: 1.7em;
	}

	.buorg-buttons {
		display: block;
		text-align: center;
	}

	#buorgul,
	#buorgpermanent {
		@extend .btn, .btn-success;
	}

	#buorgig {
		@extend .btn, .btn-warning;
	}

	@media only screen and (max-width: 700px) {
		.buorg div {
			padding: 5px 12px 5px 9px;
			line-height: 1.3em;
		}
	}

	@keyframes buorgfly {
		from {
			opacity: 0;
			transform: translateY(-50px);
		}
		to {
			opacity: 1;
			transform: translateY(0px);
		}
	}

	.buorg-fadeout {
		transition:
			visibility 0s 8.5s,
			opacity 8s ease-out 0.5s;
	}

	.buorg-icon {
		width: 22px;
		height: 16px;
		vertical-align: middle;
		position: relative;
		top: -0.05em;
		display: inline-block;
		background: no-repeat 0px center;
	}
}

;@import "sass-embedded-legacy-load-done:107";
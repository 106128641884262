$rtDateRangeInputWidth: 210px;

.rdtDateTimeRangeControl {
	.select-form-control {
		width: $rtDateRangeInputWidth;
	}

	.DateRangePickerInput_arrow_svg {
		height: 35px;
	}

	.flat-picker-wrapper {
		input {
			width: $rtDateRangeInputWidth;
			margin-right: 12px;
			background-color: white;

			&:disabled {
				@extend .form-control-plaintext;
				width: $rtDateRangeInputWidth;
				background: $form-control-plaintext-bg !important;
				padding-top: 4px;

				.react-select__single-value {
					color: $input-plaintext-color;
				}
			}
		}
	}

	.rdtDateTimeRangePresets {
		@extend .d-xl-flex, .justify-content-start, .align-items-center;
		margin-bottom: 0;
		display: flex;
	}
}

.rdtDateTimeControl {
	.flat-picker-wrapper {
		input {
			background-color: $white;
		}
	}
}

.flatpickr-months .flatpickr-disabled .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-disabled .flatpickr-next-month:hover {
	color: transparent;
}
.flatpickr-months .flatpickr-disabled .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-disabled .flatpickr-next-month:hover svg {
	fill: none;
}
.flatpickr-day.today.flatpickr-disabled {
	border-color: rgb(233 236 239) !important;
	background: #fbda656b !important;
}
.flatpickr-day.flatpickr-disabled.today:hover,
.flatpickr-day.flatpickr-disabled.today:focus {
	border-color: rgb(233 236 239) !important;
	background: #fbda656b;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
	&:not(.flatpickr-disabled) {
		background: $primary;
		border-color: $primary;
	}
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
	-webkit-box-shadow: -10px 0 0 $primary;
	box-shadow: -10px 0 0 $primary;
}
.flatpickr-day.week.selected {
	border-radius: 0;
	-webkit-box-shadow:
		-5px 0 0 $primary,
		5px 0 0 $primary;
	box-shadow:
		-5px 0 0 $primary,
		5px 0 0 $primary;
}
span.flatpickr-day.today:not(.selected),
span.flatpickr-day.prevMonthDay.today:not(.selected),
span.flatpickr-day.nextMonthDay.today:not(.selected) {
	&:not(.flatpickr-disabled) {
		border-bottom-color: #fbdb65;
		background-color: #fbdb65;
		color: rgb(48, 48, 48);
	}
}
span.flatpickr-day.today:not(.selected):hover,
span.flatpickr-day.prevMonthDay.today:not(.selected):hover,
span.flatpickr-day.nextMonthDay.today:not(.selected):hover {
	&:not(.flatpickr-disabled) {
		border: 1px solid #fbdb65;
		background-color: #fbdb65;
		color: rgb(48, 48, 48);
	}
}
span.flatpickr-day.startRange,
span.flatpickr-day.prevMonthDay.startRange,
span.flatpickr-day.nextMonthDay.startRange,
span.flatpickr-day.endRange,
span.flatpickr-day.prevMonthDay.endRange,
span.flatpickr-day.nextMonthDay.endRange {
	&:not(.flatpickr-disabled) {
		border-color: $primary;
	}
}

;@import "sass-embedded-legacy-load-done:144";
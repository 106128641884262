$help-container-toggle-width: 85px;

.help-container-toggle {
	background-color: lighten($input-bg, 5%);
	border: 1px solid #d3dae4;
	position: fixed;
	background: lighten($input-bg, 5%) !important;
	left: calc(100% - #{$help-container-toggle-width});
	top: 175px;
	user-select: none;
	z-index: map-get($z-index-map, 'help_container_toggle');
	padding: 5px;
	text-align: center;
	border-right-color: white;
	width: $help-container-toggle-width;
	border-top-left-radius: $border-radius;
	border-bottom-left-radius: $border-radius;
}

@media (min-width: map-get($grid-breakpoints, 'md')) {
	.text-end-md {
		text-align: right;
	}

	.help-container-toggle {
		top: 135px;
	}

	.help-container {
		max-height: unset;
		top: 0;
	}
}

@media (min-width: map-get($grid-breakpoints, 'lg')) {
	.pe-0-lg {
		padding-right: 0;
	}

	.ps-0-lg {
		padding-left: 0;
	}
}

.flex-no-shrink-or-grow {
	flex-grow: 0;
	flex-shrink: 0;
}

.flex-fill-no-shrink-or-grow {
	@extend .flex-fill, .flex-no-shrink-or-grow;
}

.row.row-sm {
	[class*='col-'] {
		&:not(:last-child),
		&:only-child {
			padding-right: 0;
		}
	}
}

@each $size, $length in $spacers {
	@if $size != 0 {
		.section-container-mb-#{$size} {
			& > section:not(:last-child) {
				margin-bottom: $length !important;
			}
		}
	}
}

// Add --breakpoint-$size CSS variables to root
// These are used in ViewportHelpers.ts to determine breakpoint sizes
:root {
	@each $size, $value in $grid-breakpoints {
		--breakpoint-#{$size}: #{$value};
	}
}

;@import "sass-embedded-legacy-load-done:130";
label {
	//color: darken($primary, 10%);
	font-weight: 500;
	text-transform: capitalize;

	& > * {
		text-transform: none;
	}
}

legend {
	font-size: 1.2rem;
}

label.label-90 {
	min-width: 90px;
}

label.label-100 {
	min-width: 100px;
}

.form-check > label {
	font-weight: 400;
	cursor: pointer;
}

.form-control {
	&,
	&:hover,
	&:focus {
		background-color: $input-bg;
		color: $body-color;
	}
}

.form-control-plaintext {
	background-color: $form-control-plaintext-bg;
	border-radius: $border-radius;
	// 1.5 px added to top to keep text-aligned
	padding: calc(#{$input-padding-y} + 1.5px) $input-padding-x $input-padding-y
		$input-padding-x;
	border: $input-border-width solid $form-control-plaintext-bg;
	height: $input-height;

	&.form-control-plaintext-auto-height {
		height: auto;
		word-break: break-all;
	}
}

.input-group-height {
	height: 52px;
}

input.no-arrows {
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	&[type='number'] {
		-moz-appearance: textfield;
		appearance: textfield;
	}
}

.input-group.input-group-search {
	border-radius: $border-radius;

	.btn {
		border-radius: 0.25em;
		border-width: 0;
		background-color: transparent;
		color: $white;

		&:hover {
			background-color: rgba(0, 0, 0, 0.15);
			color: $white;
		}
	}

	.input-group-prepend + .input-group-prepend,
	.input-group-append + .input-group-append {
		border-left: 1px solid $input-bg;
		border-radius: 0;
	}

	.input-group-text,
	.dropdown-toggle {
		background-color: transparent;
		color: $white;
		border: 0;
	}

	.form-control {
		border-top-width: 0.5em;
		border-bottom-width: 0.5em;
		border-top-color: transparent;
		border-bottom-color: transparent;
		border-right-width: 0;
		border-left-width: 0;

		&:first-child {
			border-left-color: transparent;
			border-left-width: 0.5em;
		}

		&:last-child {
			border-right-color: transparent;
			border-right-width: 0.5em;
		}
	}

	.dropdown-menu {
		background-color: $input-bg;
		margin-top: 0;

		.dropdown-item {
			cursor: pointer;
			color: $white;

			&.active,
			&:hover,
			&:focus {
				background-color: $input-focus-bg;
				color: $link-hover-color;
			}
		}
	}
}

.was-validated {
	.form-control-tollfree-wrapper {
		.invalid-feedback {
			display: none;
		}
	}
}

.form-control-tollfree-wrapper {
	&.is-invalid {
		input {
			border-color: $danger;
		}
	}

	.invalid-feedback {
		display: inline-block;
	}
}

.form-control-tollfree {
	letter-spacing: 0.08em;
}

.form-control.filter-placeholder::placeholder {
	font-family: 'Font Awesome 6 Free';
	font-weight: 900;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}

.form-check-label,
.form-check-input {
	cursor: pointer;
}

input,
input:required {
	box-shadow: none; //Remove red box on Firefox
}

.custom-file.custom-file-truncate-label {
	.custom-file-label {
		white-space: nowrap;
		overflow-x: hidden;
		text-overflow: ellipsis;
		padding-right: 80px;
	}
}

.bg-light {
	.border-light {
		border-color: #caced2 !important;
	}
}

.SingleDatePicker_picker__fullScreenPortal {
	.DayPicker_portal__horizontal {
		top: calc(3rem + 95px);

		> div {
			flex-direction: column;

			section {
				width: 322px;

				header {
					text-align: left;
					padding: 0;
				}
			}
		}
	}
}

.DateRangePicker {
	.DateRangePickerInput {
		background-color: unset;
	}

	.DateInput_input {
		border: unset;
	}
}

.DateRangePickerInput {
	display: block;
	width: 100%;
	height: $input-height;
	padding: 0 $input-padding-x;
	background-color: $input-bg;
	background-clip: padding-box;
	border: $input-border-width solid $input-border-color;

	// Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
	@include border-radius($input-border-radius, 0);

	@include box-shadow($input-box-shadow);
	@include transition($input-transition);

	.DateInput {
		width: 85px;
	}

	.DateInput_fang {
		transform: translateY(-6px);
	}

	.DateInput_input {
		@include font-size($input-font-size);
		color: $input-color;
		font-family: $input-font-family;
		font-weight: $input-font-weight;
		line-height: $input-line-height;
		padding: 0;
		height: calc(#{$input-height} - 3px);
		border-radius: 0;

		&.DateInput_input__focused {
			border-color: $primary;
		}
	}

	.DateRangePickerInput_arrow {
		padding: 0 1rem 0 0.5rem;
	}

	.CalendarDay__selected,
	.CalendarDay__selected:active,
	.CalendarDay__selected:hover {
		background: $primary;
		border: 1px double $primary;
		color: white;
	}
	.CalendarDay__selected_span,
	.CalendarDay__hovered_span {
		background: $gray-300;
		border: 1px double $gray-300;
		color: $body-color;
	}

	// Unstyle the caret on `<select>`s in IE10+.
	&::-ms-expand {
		background-color: transparent;
		border: 0;
	}

	// Customize the `:focus` state to imitate native WebKit styles.
	&:focus {
		color: $input-focus-color;
		background-color: $input-focus-bg;
		border-color: $input-focus-border-color;
		outline: 0;

		// Avoid using mixin so we can pass custom focus shadow properly
		@if $enable-shadows {
			box-shadow: $input-box-shadow, $input-focus-box-shadow;
		} @else {
			box-shadow: $input-focus-box-shadow;
		}
	}

	// Placeholder
	&::placeholder {
		color: $input-placeholder-color;
		// Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
		opacity: 1;
	}

	// Disabled and read-only inputs
	//
	// HTML5 says that controls under a fieldset > legend:first-child won't be
	// disabled if the fieldset is disabled. Due to implementation difficulty, we
	// don't honor that edge case; we style them as disabled anyway.
	&:disabled,
	&[readonly] {
		background-color: $input-disabled-bg;
		// iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
		opacity: 1;
	}
}

.form-group {
	&.is-condensed {
		margin-bottom: 0.25em;

		.form-control-plaintext {
			padding-top: calc(0.375rem + 1px);
			padding-bottom: calc(0.375rem + 1px);
			margin-bottom: 0;
			font-size: inherit;
			line-height: 1.5;
			height: unset;
		}
	}
}

form.rt-ui-form {
	// &.edit-mode {
	// 	//anything else
	// }
	&.display-mode {
		.inline-row {
			&:nth-child(2n) {
				background-color: $gray-200;
			}

			& > .col:not(label) {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				text-align: right;
			}
		}
	}
}

.form-group,
body > div {
	& > .SingleDatePicker_picker {
		z-index: map-get($z-index-map, 'date_picker') !important;
	}

	.SingleDatePickerInput {
		@include border-radius($input-border-radius, 0);
		border: 0;
	}

	.SingleDatePicker {
		@include border-radius($input-border-radius, 0);

		& > div {
			@include border-radius($input-border-radius, 0);
		}

		.time-controls {
			.form-control,
			.form-select {
				border-color: $input-border-color;
				padding: $input-padding-x;
				//override was-validated parent
				background-image: none;
			}
			.form-select {
				//override was-validated parent
				background-image: escape-svg($form-select-indicator) !important;
				padding-right: $form-select-padding-x !important;
			}
		}
	}

	.SingleDatePicker_picker {
		@include border-radius($input-border-radius, 0);
		z-index: map-get($z-index-map, 'date_picker') !important;
	}

	.DateInput {
		@include border-radius($input-border-radius, 0);
	}

	.DateInput_input__focused {
		background: $input-bg;
		outline: none;
	}

	.CalendarDay__selected,
	.CalendarDay__selected:active,
	.CalendarDay__selected:hover {
		background: $primary;
		border: 1px double $primary;
		color: #fff;
	}

	.DayPickerKeyboardShortcuts_show__bottomRight::before {
		border-right: 33px solid $primary;
	}

	.DayPicker > div {
		@extend .d-flex, .justify-content-between;
		@include border-radius($input-border-radius, 0);
	}

	.DayPicker__horizontal {
		max-width: 600px;
		min-width: 600px;
		max-height: 320px;
		min-height: 320px;
	}

	.DayPicker_calendarInfo__horizontal {
		width: 100%;
	}

	.DateInput_input {
		display: block;
		width: 100%;
		height: $input-height;
		padding: $input-padding-y $input-padding-x;
		font-family: $input-font-family;
		@include font-size($input-font-size);
		font-weight: $input-font-weight;
		line-height: $input-line-height;
		color: $input-color;
		background-color: $input-bg;
		background-clip: padding-box;
		border: $input-border-width solid $input-border-color;

		// Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
		@include border-radius($input-border-radius, 0);

		@include box-shadow($input-box-shadow);
		@include transition($input-transition);

		// Unstyle the caret on `<select>`s in IE10+.
		&::-ms-expand {
			background-color: transparent;
			border: 0;
		}

		// Customize the `:focus` state to imitate native WebKit styles.
		&:focus {
			color: $input-focus-color;
			background-color: $input-focus-bg;
			border-color: $input-focus-border-color;
			outline: 0;

			// Avoid using mixin so we can pass custom focus shadow properly
			@if $enable-shadows {
				box-shadow: $input-box-shadow, $input-focus-box-shadow;
			} @else {
				box-shadow: $input-focus-box-shadow;
			}
		}

		// Placeholder
		&::placeholder {
			color: $input-placeholder-color;
			// Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
			opacity: 1;
		}

		// Disabled and read-only inputs
		//
		// HTML5 says that controls under a fieldset > legend:first-child won't be
		// disabled if the fieldset is disabled. Due to implementation difficulty, we
		// don't honor that edge case; we style them as disabled anyway.
		&:disabled,
		&[readonly] {
			background-color: $input-disabled-bg;
			// iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
			opacity: 1;
		}
	}
}

.input-action {
	color: hsl(0, 0%, 80%) !important;

	cursor: pointer;

	&.input-action-right {
		position: absolute;
		right: 8px;
		top: 8px;
	}

	&:hover {
		color: hsl(0, 0%, 60%) !important;
	}
}

.input-group .input-group-text + .select-form-control,
.input-group .input-group-text + .form-control-plaintext {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0;

	.react-select__control {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.react-select__control--is-disabled {
		@extend .form-control-plaintext;
		background: $form-control-plaintext-bg !important;
		border-left: 1px solid #cccccc !important;
		padding-top: 4px;

		.react-select__single-value {
			color: $input-plaintext-color;
		}
	}
}

//Append input-group-addon
.input-group .select-form-control:not(:last-child) {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0;

	// & + .input-group-append {
	//   margin-left: unset;
	// }

	.react-select__control {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.react-select__control--is-disabled {
		@extend .form-control-plaintext;
		background: $form-control-plaintext-bg !important;
		border-right: 1px solid #cccccc !important;
		padding-top: 4px;

		.react-select__single-value {
			color: $input-plaintext-color;
		}
	}
}

// .select-form-control.select-form-control-scenario-menu {
//   .react-select__menu {
//     min-width: 179px;
//     right: 0;
//   }
// }

.input-group {
	.input-group-text {
		+ .form-control-plaintext-auto-height {
			border-top-left-radius: unset;
			border-bottom-left-radius: unset;
			border: 1px solid #cccccc;
		}
	}
}

;@import "sass-embedded-legacy-load-done:127";
$tollfree-item-padding: 2px;

.tollfree-numbers-textarea {
	@extend .form-control;
	@extend .d-flex;
	@extend .justify-content-start;
	@extend .align-items-start;
	@extend .flex-wrap;
	cursor: text;
	max-height: 102px;
	min-height: 74px;
	overflow-y: auto;

	.tollfree-numbers-textarea-input {
		@extend .flex-fill;

		display: inline-block;
		padding: $tollfree-item-padding + 1;

		input {
			@extend .text-monospace;

			display: block;
			outline: none;
			border: none;
		}
	}

	li:not(.tollfree-numbers-textarea-input) {
		@extend .text-monospace;

		display: inline-block;
		padding: $tollfree-item-padding;
		border: 1px solid $light;
		border-radius: $border-radius;
		margin-right: 5px;
		margin-bottom: 2px;
		cursor: pointer;

		&:after {
			padding-left: 2px;
			padding-right: 2px;
			margin-left: 2px;
			color: darken($light, 15%);
			font-family: 'Font Awesome 6 Free';
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			font-weight: 400;
			text-rendering: auto;
			line-height: 1;
			content: '\f057'; //fa-times-circle
		}

		&:hover:after {
			color: $danger;
		}
	}
}

;@import "sass-embedded-legacy-load-done:135";
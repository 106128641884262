.mermaid-diagram {
	.rect {
		fill: transparent;

		&:hover {
			cursor: pointer;
			fill: rgba(85, 98, 164, 0.1);
		}
	}
}

.sip-trace-content {
	@extend .form-control-plaintext;
	background: $form-control-plaintext-bg !important;
	&:hover {
		background: $form-control-plaintext-bg !important;
	}
}

;@import "sass-embedded-legacy-load-done:110";
$white: #fff !default;
$gray-100: #f8f9fe !default;
$gray-200: #eaeff3 !default;
$gray-300: #e9ecef !default;
$gray-400: #d9d9d9 !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #242d35 !default;
$gray-900: #0e0e0e !default;
$black: #000 !default;

$blue: #5562a4 !default; //rgb(57, 108, 196) !default;
$indigo: #9b59b6 !default;
$purple: #764387 !default;
$pink: #bf004a !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #f6a821 !default;
$green: #3fb618 !default;
$teal: #54a457 !default;
$cyan: #3498db !default;

$dark: $gray-800 !default;
$primary: $blue !default;
$secondary: $purple !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-300 !default;
$enable-gradients: false !default;

//Fonts
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700|IBM+Plex+Serif:400,600&display=swap');

$font-family-sans-serif:
	'IBM Plex Sans',
	-apple-system,
	BlinkMacSystemFont,
	'Segoe UI',
	Roboto,
	'Helvetica Neue',
	Arial,
	sans-serif,
	'Apple Color Emoji',
	'Segoe UI Emoji',
	'Segoe UI Symbol' !default;
$font-family-serif: 'IBM Plex Serif', serif;
$font-family-monospace: $font-family-sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

.text-serif {
	font-family: $font-family-serif;
}

.text-monospace {
	font-family: $font-family-monospace;
	font-variant-numeric: lining-nums tabular-nums;
}

//$headings-font-family

;@import "sass-embedded-legacy-load-done:0";
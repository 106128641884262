.slider-success {
	.DefaultProgressBar_progressBar {
		background-color: $success;
	}
}

.slider-warning {
	.DefaultProgressBar_progressBar {
		background-color: $info;
	}
}

.slider-danger {
	.DefaultProgressBar_progressBar {
		background-color: $orange;
	}
}

.rheostat {
	.DefaultHandle_handle {
		border-radius: 0.75em;

		&:hover {
			&::before {
				background-color: darken(#dadfe8, 15%);
			}

			&::after {
				background-color: darken(#dadfe8, 15%);
			}
		}

		&::before {
			left: 9px;
		}

		&::after {
			left: 12px;
		}
	}

	.DefaultProgressBar_progressBar {
		cursor: pointer;
	}

	.handleContainer {
		cursor: pointer;
		background: $input-focus-bg;
	}
}

;@import "sass-embedded-legacy-load-done:128";
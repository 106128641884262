.tooltip {
	transition: opacity 50ms ease-out;

	&.show {
		transition: opacity 250ms ease-in;

		.tooltip-inner {
			@extend .shadow-sm;

			padding: 10px 7px;
			white-space: normal;
			max-width: 300px;
			//text-align: left;
			//background: $body-bg;
			//color: $body-color;
		}
	}
}

.venntooltip {
	position: absolute;
	text-align: center;
	width: auto;
	height: auto;
	background: $body-color;
	color: $gray-300;
	padding: 0.5rem 1rem;
	border: 0px;
	border-radius: $border-radius;
	opacity: 0;
	z-index: map-get($z-index-map, 'tooltip');
}

;@import "sass-embedded-legacy-load-done:131";
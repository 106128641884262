.rhap_container {
	@extend .card;

	box-shadow: none;

	.rhap_button-clear.rhap_main-controls-button.rhap_play-pause-button {
		height: 42px;
	}
}

;@import "sass-embedded-legacy-load-done:139";
.beautifulNight {
	background-color: #113755;
	height: 350px;
	width: auto;
	border-radius: 20%;
	margin: 0 auto;
	position: relative;
	overflow: hidden;

	.mountains1 {
		position: absolute;
		height: 60px;
		width: 450px;
		background-color: #144569;
		bottom: 65px;
		right: 0;
		transform: rotate(5deg);
		-webkit-transform: rotate(5deg);
	}
	.m1 {
		height: 0;
		width: 0;
		border-bottom: 70px solid #144569;
		border-left: 25px solid transparent;
		border-right: 50px solid transparent;
		position: absolute;
		left: 70px;
		bottom: 10px;
	}
	.m2 {
		height: 80px;
		width: 120px;
		background-color: #144569;
		position: absolute;
		left: 113px;
		bottom: 30px;
		transform: rotate(30deg);
		-webkit-transform: rotate(30deg);
	}
	.m3 {
		height: 50px;
		width: 175px;
		background-color: #144569;
		position: absolute;
		left: 210px;
		bottom: 35px;
		transform: rotate(25deg);
		-webkit-transform: rotate(25deg);
	}
	.m4 {
		border-bottom: 55px solid #144569;
		border-left: 105px solid transparent;
		border-right: 50px solid transparent;
		position: absolute;
		left: 330px;
		bottom: 50px;
	}
	.moon {
		height: 35px;
		width: 35px;
		background-color: #ffffff;
		border-radius: 50%;
		position: absolute;
		left: 90px;
		top: 70px;
		box-shadow:
			0 0 1px 15px rgba(32, 105, 149, 0.6),
			0 0 1px 35px rgba(32, 105, 149, 0.5),
			0 0 1px 50px rgba(32, 105, 149, 0.3),
			0 0 1px 70px rgba(32, 105, 149, 0.1);
	}
	.moon:before {
		position: absolute;
		content: '';
		background-color: #ebefe7;
		height: 10px;
		width: 10px;
		border-radius: 50%;
		top: 8px;
		left: 6px;
	}
	.moon:after {
		position: absolute;
		content: '';
		background-color: #ebefe7;
		height: 7px;
		width: 7px;
		border-radius: 50%;
		top: 20px;
		left: 12px;
	}
	.land {
		height: 80px;
		width: 450px;
		background-color: #041524;
		position: absolute;
		bottom: -5px;
		transform: rotate(5deg);
		-webkit-transform: rotate(5deg);
	}
	.windmill {
		height: 0;
		width: 20px;
		border-bottom: 38px solid #041524;
		border-left: 2.5px solid transparent;
		border-right: 2.5px solid transparent;
		position: absolute;
		bottom: 78px;
		right: 210px;
	}
	.windmill:before {
		position: absolute;
		content: '';
		background-color: #041524;
		height: 10px;
		width: 20px;
		bottom: -1px;
		left: 0.2px;
		border-radius: 20px 20px 0 0;
	}
	.top {
		position: absolute;
		content: '';
		width: 24px;
		height: 0;
		border-bottom: 6px solid #0b2231;
		border-left: 1px solid transparent;
		border-right: 1px solid transparent;
		position: absolute;
		right: -3.2px;
		top: 1px;
	}
	.light {
		height: 4px;
		width: 4px;
		background-color: #8daf76;
		position: absolute;
		top: 12px;
		left: 8px;
		border-radius: 50%;
		box-shadow:
			0 0 1px 2px rgba(141, 175, 118, 0.3),
			0 0 1px 4px rgba(141, 175, 118, 0.2);
	}
	.door {
		background-color: #0f304f;
		height: 8px;
		width: 8px;
		position: absolute;
		bottom: -36px;
		left: 5.5px;
		border-radius: 10px 10px 0 0;
	}
	.blades {
		height: 60px;
		width: 60px;
		position: absolute;
		bottom: -30px;
		left: -20px;
		animation: rotate 5s infinite linear;
		-webkit-animation: rotate 5s infinite linear;
	}
	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}
	@-webkit-keyframes rotate {
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	.bl {
		height: 25px;
		width: 8px;
		background-color: #041524;
		position: absolute;
	}
	.bl:before {
		position: absolute;
		content: '';
		background-color: #041524;
		height: 6px;
		width: 1.5px;
		top: 25px;
		left: 3px;
	}
	.bl1 {
		margin: auto;
		left: 0;
		right: 0;
	}
	.bl2 {
		margin: auto;
		top: 0;
		bottom: 0;
		transform: rotate(-90deg);
		-webkit-transform: rotate(-90deg);
		left: 8px;
	}
	.bl3 {
		margin: auto;
		left: 0;
		right: 0;
		bottom: 0;
		transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
	}
	.bl4 {
		margin: auto;
		top: 0;
		bottom: 0;
		transform: rotate(90deg);
		-webkit-transform: rotate(90deg);
		right: 8px;
	}
	.star {
		height: 3px;
		width: 3px;
		background-color: white;
		position: absolute;
		border-radius: 50%;
		opacity: 0.2;
		animation: twinkle 2s infinite;
		-webkit-animation: twinkle 2s infinite;
	}
	@keyframes twinkle {
		50% {
			opacity: 1;
			transform: scale(1.2);
		}
	}
	@-webkit-keyframes twinkle {
		50% {
			opacity: 1;
			-webkit-transform: scale(1.2);
		}
	}
	.s1 {
		top: 80px;
		left: 300px;
	}
	.s2 {
		top: 100px;
		left: 50px;
		animation-delay: 0.5s;
		-webkit-animation-delay: 0.5s;
	}
	.s3 {
		left: 120px;
		top: 150px;
		animation-delay: 0.8s;
		-webkit-animation-delay: 0.8s;
	}
	.s4 {
		left: 180px;
		top: 50px;
		animation-delay: 0.2s;
		-webkit-animation-delay: 0.2s;
	}
	.s5 {
		left: 230px;
		top: 110px;
		animation-delay: 0.9s;
		-webkit-animation-delay: 0.9s;
	}
	.s6 {
		left: 280px;
		top: 160px;
		animation-delay: 0.35s;
		-webkit-animation-delay: 0.35s;
	}
	.s7 {
		left: 240px;
		top: 30px;
		animation-delay: 0.1s;
		-webkit-animation-delay: 0.1s;
	}
	.shooting {
		height: 3px;
		width: 40px;
		background-color: white;
		transform: rotate(-30deg);
		-webkit-transform: rotate(-30deg);
		position: absolute;
		right: -10px;
		opacity: 0.2;
		animation: shoot 5s infinite linear;
		-webkit-animation: shoot 5s infinite linear;
	}
	@keyframes shoot {
		10% {
			transform: rotate(-30deg) translate(-550px, 0px);
		}
		100% {
			transform: rotate(-30deg) translate(-550px, 0px);
		}
	}
	@-webkit-keyframes shoot {
		10% {
			-webkit-transform: rotate(-30deg) translate(-550px, 0px);
		}
		100% {
			-webkit-transform: rotate(-30deg) translate(-550px, 0px);
		}
	}
	.tree {
		height: 0;
		width: 0;
		border-bottom: 32px solid #041524;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		position: absolute;
		bottom: 78px;
		left: 50px;
	}
	.tree:before {
		position: absolute;
		content: '';
		border-bottom: 25px solid #041524;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		position: absolute;
		bottom: -35px;
		left: 3px;
	}
	a {
		color: #113755;
		font-family: 'Rubik', sans-serif;
		position: absolute;
		right: 15px;
		top: 15px;
		border: 2px solid #113755;
		text-decoration: none;
	}
}

;@import "sass-embedded-legacy-load-done:115";
.flow-step-list-component {
	@extend .px-3, .py-2;

	&:nth-child(even) {
		@extend .bg-light;

		.form-control-plaintext {
			background-color: $gray-100;
		}
	}
}

.flow-step-group-container {
	&:not(:only-child) {
		&:not(:last-child) {
			@extend .pb-2;
		}

		&:not(:first-child) {
			@extend .pt-2, .border-top, .border-light;
		}
	}
}

;@import "sass-embedded-legacy-load-done:121";
.react-numeric-input {
	.material-style {
		width: 100%;
		padding: 10px 14px 5px 14px;
		color: #1c2025;
		border-radius: 4px;
		border: 1px solid #e9ecef;

		&.disabled {
			color: hsl(0deg 0% 65.88%);
			background: none;

			&:hover {
				border: 1px solid #e9ecef;
			}
		}

		&:focus,
		&:hover {
			outline: none;
			border: 1px solid #1c2025;
		}
	}
	b {
		display: none;
	}
}

;@import "sass-embedded-legacy-load-done:138";
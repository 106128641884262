.alert-sm {
	padding: 2px 12px;
	font-size: 0.875rem;
	font-weight: 400;
	font-size: 1em;
}

.alert-transparent {
	background-color: transparent;
	color: $body-color;
	padding-left: 0;
	padding-right: 0;
	//border-color: $info;
}

.alert > i.fas,
.alert > i.far {
	line-height: 1.5;
}

.alert a[href] {
	text-decoration: underline;
}

.alert {
	word-break: break-word;
}

//Alert outlines
@each $color, $value in $theme-colors {
	.alert-#{$color}-outline {
		position: relative;
		background-color: $white;
		border-color: $value;
		border-left-width: 3rem;

		h6,
		.h6 {
			color: $value;
		}

		&::before {
			position: absolute;
			left: -2.1rem;
			top: calc(50% - 0.5em);

			color: $white;
			font-family: 'Font Awesome 6 Free';
			font-weight: 900;
			font-size: 18px;
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;
			content: '\f05a'; // fas-info-circle
		}
	}
}

.alert-warning-outline::before {
	content: '\f06a'; // fas-exclamation-circle
}

.alert-danger-outline::before {
	content: '\f071'; //// fas-exclamation-triangle
}

;@import "sass-embedded-legacy-load-done:111";
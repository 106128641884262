.table {
	th {
		font-weight: normal;
		border-top: none;
	}

	th,
	td {
		padding: 7px 5px;
		border-right: 1px solid rgba(0, 0, 0, 0.02);
	}
}

.apply-data-height-430 {
	height: 430px;
}

.apply-data-height-470 {
	height: 470px;
}

.apply-data-height-370 {
	height: 370px;
}

.apply-data-height-265 {
	height: 265px;
}

.apply-data-height-305 {
	height: 305px;
}
.nested-table {
	tr.is-nested {
		cursor: pointer;
		width: 100%;

		&:hover {
			background-color: $gray-100;
		}

		td {
			width: 100%;
		}
	}
}

;@import "sass-embedded-legacy-load-done:129";
.left-nav {
	padding: 0 8px;
	background: $brand-gradient;

	.navbar-brand {
		height: 68px;
	}

	.navbar-toggler {
		padding-top: 12px;
	}

	.navbar-collapse {
		align-items: flex-start;
		width: 100%;
	}

	.navbar-nav {
		width: 100%;

		.nav-header,
		.nav-link {
			position: relative;
			padding: 8px 12px;
			font-size: 13px;
			color: $white;
			font-weight: normal;
			border-radius: 0;
			user-select: none;

			&.active,
			&.active:hover,
			&:hover {
				color: $white;
			}

			&.nav-link-info.active,
			&.nav-link-info:hover {
				color: $white;
			}

			&:not(a) {
				font-weight: 500;
			}
		}

		.nav-link {
			padding-left: 36px;
		}

		.nesting-container {
			padding-left: 15px;
		}

		.nav-description {
			display: none;
		}

		.nav-header {
			cursor: pointer;
			position: relative;
			color: $white;
			padding-left: 12px;
			font-size: 13.5px;

			&.no-menu {
				a,
				&.active,
				&:hover {
					color: $white;
				}
			}

			//Unopened Sub-menu
			&:not(.no-menu) {
				&::after {
					font-family: 'Font Awesome 6 Free';
					-moz-osx-font-smoothing: grayscale;
					-webkit-font-smoothing: antialiased;
					display: inline-block;
					font-style: normal;
					font-variant: normal;
					font-weight: 900;
					text-rendering: auto;
					line-height: 1;
					font-size: 14px;
					position: absolute;
					content: '\f107'; //fa-angle-down
					right: 10px;
					top: 12px;
				}

				//Open Sub-menu
				&.open::after {
					content: '\f106'; //fa-angle-up
				}
			}

			&.open {
				position: relative;
			}

			&.nav-header-lg {
				padding: 20px 20px;
				// font-size: 1.1em;
				border-bottom: 1px solid $leftNavBorderColor;

				&:hover,
				&:hover::after,
				&.open {
					color: $white;
				}

				&::after {
					top: 22px;
				}

				+ .collapse .list-group .nav-link,
				+ .collapsing .list-group .nav-link {
					padding-left: 22px;
					// font-size: 1.1em;
				}
			}
		}

		.collapse > .list-group,
		.collapsing > .list-group {
			background-color: transparent;
			//border-bottom: 1px solid $leftNavBorderColor;
		}
	}
}

@media (min-width: 768px) {
	.left-nav {
		flex-flow: column;
		align-items: flex-start;
		padding-right: 0;
		padding-top: 1.5rem;
		background: $body-bg !important;
		//border-right: 1px solid #d3dae4;

		.navbar-brand {
			width: 100%;
			padding-top: 0;
			height: unset;
		}

		.navbar-nav {
			.nav-header.nav-link {
				font-size: 13.5px;
				font-weight: normal;

				&:not(a) {
					font-weight: 500;
				}
			}

			.nav-link {
				font-weight: 400;
			}

			.nav-header,
			.nav-link,
			.nav-link.no-menu {
				color: $gray-900;
				margin-bottom: 0.1rem;

				&.active,
				&.active:hover,
				&:hover {
					background: rgba($primary, 0.95);
					border-radius: $border-radius;
					color: $white;
				}

				&:hover {
					background: rgba($primary, 0.65);
				}
			}
		}

		.nav-link:hover {
			z-index: map-get($z-index-map, 'navigation');

			.nav-description {
				position: absolute;
				display: block;
				z-index: map-get($z-index-map, 'navigation');
				padding: 10px 12px;
				left: calc(100% + 8px);
				top: 0;
				width: 200px;
				background: $primary;
				color: $white;
				border-radius: $border-radius;
				//border-bottom-right-radius: $border-radius;
				//border: 1px solid $white;
				//border-left: none;
			}
		}
	}
}

.nav-pills {
	.nav-item > .btn-group {
		height: 100%;
		min-width: 125px;
		text-align: center;

		.btn {
			color: $white;
		}
	}

	.dropdown.show {
		background: unset;
	}

	.nav-link {
		min-width: 125px;
		text-align: center;
		margin-right: 5px;
		border: 1px solid $primary;
		color: $white;
		border-radius: $border-radius;

		&.nav-link-back {
			min-width: unset;
		}

		&.disabled,
		&.disabled:hover {
			border-color: $text-muted;
			color: $text-muted;
			cursor: not-allowed;
		}

		&:not([tabindex]):not(.disabled) {
			color: $nav-btn-color;

			&:hover,
			&.active {
				background-color: $primary;
				color: $white;
				cursor: pointer;
			}
		}
	}

	.dropdown-menu {
		background-color: $primary;
		color: $white;
	}

	.show > .nav-link:not([tabindex]):not(.disabled) {
		background: $primary;
		color: $white;

		& + .dropdown-menu {
			.dropdown-item:hover,
			.dropdown-item:focus,
			.dropdown-item.active {
				background: darken($primary, 15%);
				color: $white;
				cursor: pointer;
			}
		}
	}
}

.dropdown.show,
.btn-group.show:not(.btn-group-default) {
	//background: $primary;
	//color: $white;

	& > .dropdown-menu {
		//background-color: $primary;
		border-color: $dark;

		.dropdown-item:not(.disabled),
		.dropdown-item:not(.disabled):hover,
		.dropdown-item:not(.disabled):focus,
		.dropdown-item:not(.disabled).active {
			//background: darken($primary, 15%);
			color: $dark;
			cursor: pointer;
		}
	}
}

.btn-group.show.btn-group-default {
	.dropdown-menu {
		border-color: $white;

		.dropdown-item {
			color: $body-color;
		}
	}
}

.global-search {
	&::placeholder {
		color: $white;
	}

	&,
	& + .input-group-append > .btn {
		background-color: transparent !important;
		border: 0;
		//border-top: 1px solid $gray-500;
		//border-bottom: 1px solid $gray-500;
		//border-radius: 0!important;
		padding: 1rem 0;
		color: $white !important;
	}
}

.card-header > .nav.nav-steps {
	.nav-item {
		//width: 250px;
		color: $gray-600;
		text-align: center;
		user-select: none;

		// &:not(:first-child):not(:last-child) {
		//   text-align: center;
		// }

		// &:last-child {
		//   text-align: right;
		// }

		&.active {
			color: $primary;
			border-bottom: 1px solid $primary;
			margin-bottom: calc(-#{$card-spacer-y} - 1px);
		}

		.nav-link {
			font-weight: 600;
			padding-left: 0;

			// &::before {
			//   font-family: "Font Awesome 6 Free";
			//   font-weight: 900;
			//   font-size: 12px;
			//   -moz-osx-font-smoothing: grayscale;
			//   -webkit-font-smoothing: antialiased;
			//   display: inline-block;
			//   font-style: normal;
			//   font-variant: normal;
			//   text-rendering: auto;
			//   line-height: 1;
			//   padding-top: 2px;
			//   padding-right: 0.50em;
			//   content: "\f111";
			// }
		}
	}
}

.top-nav {
	@extend .subtleSlideInDown,
		.d-none,
		.d-md-flex,
		.bg-brand-gradient,
		.justify-content-between;

	height: 60px;

	.left-nav-width {
		width: $nav-width;
	}

	.fa-layers {
		position: relative;
		display: block;
		width: 100%;
		text-align: center;

		.fas.show-swing {
			@extend .animate__animated;
			animation-duration: 750ms;
			animation-iteration-count: 3;
			animation-name: swing;
		}

		.fa-layers-counter {
			display: inline-block;
			position: absolute;
			text-align: center;
			border-radius: 1em;
			box-sizing: border-box;
			color: #fff;
			font-size: 10.5px;
			min-width: 1.5em;
			line-height: 1;
			height: 1.5em;
			overflow: hidden;
			padding: 0.25em;
			text-overflow: ellipsis;

			&.fa-layers-counter-top-right {
				right: 0;
				top: 0;
			}

			&.fa-layers-counter-top-left {
				left: 0;
				top: 0;
			}

			&.fa-layers-counter-bottom-left {
				left: 0;
				bottom: 0;
			}

			&.fa-layers-counter-bottom-right {
				right: 0;
				bottom: 0;
			}
		}
	}

	.top-nav-right {
		@extend .d-flex, .justify-content-end, .align-items-center;
		padding-right: 2rem;

		.btn:not(.btn-light) {
			@extend .ms-2;
			padding-top: 3px;
		}
	}

	.btn:not(.btn-light) {
		@extend .px-1;

		height: 60px;
		width: 60px;
		font-size: 24px;
		text-align: center;
		color: $white;
		border-radius: 0;
		background: transparent;
		border-color: transparent;

		&:hover,
		&:active {
			background: $primary;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
	.navbar-light .navbar-nav {
		.show > .nav-link,
		.nav-link.active {
			color: $white !important;

			&.active,
			&.active:hover,
			&:hover {
				color: $white !important;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:123";
.dashboard-card {
	@extend .card;

	.dashboard-card-row {
		@extend .row, .g-0;

		.dashboard-card-stat {
			@extend .border, .border-light, .col, .d-flex, .align-items-center, .p-3;

			--stat-fontawesome-unicode: '\f058'; // fas-check-circle
			--stat-fontawesome-family: 'Font Awesome 6 Free';

			&:first-child {
				@extend .border-start-0;
			}

			&:last-child {
				@extend .border-end-0;
			}

			.dashboard-card-stat-header {
				@extend .flex-fill, .h5, .mb-0, .fw-bold, .align-items-center;

				.dashboard-card-stat-subheader {
					@extend .fw-normal, .fs-6, .mt-1;

					color: $gray-500;
					font-size: 1rem;
				}
			}

			&.dashboard-card-stat-info {
				--stat-fontawesome-unicode: '\f05a'; // fas-info-circle
			}

			&.dashboard-card-stat-warning {
				--stat-fontawesome-unicode: '\f06a'; // fas-exclamation-circle
			}

			&.dashboard-card-stat-danger {
				--stat-fontawesome-unicode: '\f071'; // fas-exclamation-triangle
			}

			&.dashboard-card-stat-light {
				--stat-fontawesome-unicode: '\f111'; // fas-circle
			}

			@each $color, $value in $theme-colors {
				&.dashboard-card-stat-#{$color} {
					&::after {
						text-align: center;
						width: 3rem;
						color: $value;
						font-family: var(--stat-fontawesome-family);
						font-weight: 900;
						font-size: 20px;
						-moz-osx-font-smoothing: grayscale;
						-webkit-font-smoothing: antialiased;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						content: var(--stat-fontawesome-unicode);
					}
				}
			}
		}

		&:first-child {
			.dashboard-card-stat {
				@extend .border-top-0;
			}
		}

		&:last-child {
			.dashboard-card-stat {
				@extend .border-bottom-0;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:119";
nav.rtNotification:hover {
	cursor: pointer;

	.rtNotification__heading {
		text-decoration: underline;
	}
}

.rtNotification {
	$self: &;

	--opacity: 1;
	$borderSize: 0.5rem;
	$backgroundOpacity: 0.2;
	background-color: $gray-100;
	border-bottom: 1px solid $light !important;

	&--light {
		--opacity: 0.9;
		background-color: $white;
	}

	&__text {
		color: rgba($dark, var(--opacity));
	}

	.rtNotification__heading__icon {
		@extend .me-1;
	}

	&--Clickable:hover {
		cursor: pointer;
		background: $light;
	}

	&--Critical,
	&--Major {
		border-left: $borderSize solid rgba($danger, var(--opacity)) !important;

		&--fill {
			background-color: rgba($danger, $backgroundOpacity) !important;
		}

		#{ $self }__heading {
			color: rgba($danger, var(--opacity));
		}

		#{ $self }__subject {
			color: rgba($danger, var(--opacity));
		}
	}

	&--Minor {
		border-left: $borderSize solid rgba($warning, var(--opacity)) !important;
		//border-left: $borderSize solid transparent !important;

		&--fill {
			background-color: rgba($warning, $backgroundOpacity) !important;
		}

		#{ $self }__heading {
			color: rgba(darken($warning, 10%), var(--opacity));
		}

		#{ $self }__subject {
			color: rgba($warning, var(--opacity));
		}
	}

	&--Success {
		border-left: $borderSize solid rgba($success, var(--opacity)) !important;
		//border-left: $borderSize solid transparent !important;

		&--fill {
			background-color: rgba($success, $backgroundOpacity) !important;
		}

		#{ $self }__heading {
			color: rgba(darken($success, 10%), var(--opacity));
		}

		#{ $self }__subject {
			color: rgba(darken($success, 10%), var(--opacity));
		}
	}

	&--Info {
		border-left: $borderSize solid rgba($primary, var(--opacity)) !important;
		//border-left: $borderSize solid transparent !important;

		&--fill {
			background-color: rgba($white, $backgroundOpacity) !important;
		}

		#{ $self }__heading {
			color: rgba($primary, var(--opacity));
		}

		#{ $self }__subject {
			color: rgba($primary, var(--opacity));
		}
	}
}

;@import "sass-embedded-legacy-load-done:142";
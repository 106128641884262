.dnd-file-uploader {
	@extend .p-5;
	min-height: 120px;
	padding: 2rem !important;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px dashed $info;
	border-radius: $border-radius;

	.dnd-file-uploader-manual {
		position: relative;

		input[type='file'] {
			cursor: pointer;
			position: absolute;
			z-index: map-get($z-index-map, 'dnd_file');
			bottom: 0;
			top: 0;
			left: 0;
			opacity: 0;
			width: 100%;
		}
	}

	.fas {
		color: $info;
	}

	&.dragsuccess {
		justify-content: start;
		background-color: $success;
		color: $white;
		border: none;
	}

	&.dragover {
		border-color: $success;
		background-color: $gray-200;

		.fas {
			@extend .animate__animated;
			animation-name: swing;
			animation-duration: 1s;
			animation-iteration-count: infinite;
			color: $success;
		}

		&.dragover-danger {
			justify-content: start;
			background-color: $danger;
			color: $white;
			border: none;

			.fas {
				color: $danger;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:124";
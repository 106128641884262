:root {
	--toastify-toast-width: 550px;
}

html {
	height: 100%;
	overflow: auto;
}

body {
	height: 100%;
}

.bg-primary {
	@extend .text-white;
}

.bg-purple {
	@extend .text-white;
	background-color: $purple;
}

.link-hover-bg {
	background-color: $link-hover-color;
}

.link-color {
	color: $link-hover-color;
}

.page {
	margin-top: 1.5rem;
	padding-bottom: 1.5rem;
}

.header-container {
	@extend .container-fluid;
	@include media-breakpoint-up(xxxl) {
		max-width: map-get($container-max-widths, 'xxxl');
	}
	@media (min-width: 1800px) {
		max-width: 100vw;
	}
}

.page-container {
	@extend .container-fluid;
	@include media-breakpoint-up(xxxl) {
		max-width: map-get($container-max-widths, 'xxxl');
	}
	@media (min-width: 1800px) {
		max-width: 100vw;
	}

	padding: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	//overflow-x: hidden;
	transition: background-color 250ms ease-out;

	& > nav {
		@extend .subtleSlideInLeft;
		flex-basis: 100%;
		transition: flex-basis 100ms ease-in;
		flex-grow: 0;
		flex-shrink: 0;
		//border-right: 1px solid $gray-200;
		//background-color: $white;
	}

	& > main {
		padding: 0 2rem 2rem 2rem;
		flex-grow: 1;
		flex-shrink: 0;
		max-width: 100%;
		//flex-basis: calc(100vw - 200px);
	}
}

@media (min-width: map-get($grid-breakpoints, 'md')) {
	.page-container {
		flex-wrap: nowrap;
		flex-direction: row;

		& > nav,
		& > main {
			//min-height: calc(100vh - 60px);
			min-width: 0;
		}

		& > nav {
			flex-basis: $nav-width;
		}

		main {
			margin-left: 0;
			flex-basis: 100%;
			padding: 0;
		}

		nav + main {
			flex-basis: calc(100% - #{$nav-width});
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
}

.page-container.collapsed-nav {
	& > nav {
		flex-basis: 5vw;
	}

	& > main {
		flex-basis: 95vw;
	}
}

//MARGIN
.margin-top {
	margin-top: 2rem;
}

.margin-bottom {
	margin-bottom: 2rem;
}

.margin-left {
	margin-left: 2rem;
}

.margin-right {
	margin-right: 2rem;
}

//CARDS
.shadow {
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.border-radius {
	border-radius: $border-radius;
}

//BTNS
.btn-group-lg > .btn,
.btn-lg {
	padding: 0.5rem 1rem;
	font-size: 1.25rem;
	line-height: 1.5;
	border-radius: 0.3rem;
}

.v-align-container {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
}

@keyframes asideLeftOpen {
	0% {
		transform: translate3d(0, 0, 0);
	}

	50% {
		transform: translate3d(4%, 0, 0);
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes asideLeftClose {
	from {
		opacity: 1;
		width: $left-aside-width;
	}

	to {
		opacity: 0;
		width: 0;
	}
}

.aside-left {
	display: none;

	position: fixed;
	background-color: $white;
	top: 120px;
	font-size: $font-size-base;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;

	.list-group > .nav-link {
		outline: none;
		color: $dark;
		padding: 5px 10px;
		font-size: 1rem !important;

		.card {
			box-shadow: none;
		}

		&:hover,
		&.active,
		&.focus {
			color: $primary;
			text-decoration-line: underline;
			text-decoration-color: $primary;
			text-decoration-style: dotted;
		}
	}

	&.opened {
		@extend .card-body;
		@extend .shadow-sm, .border-end, .border-light;

		display: block;
		z-index: map-get($z-index-map, 'aside_left');
		animation-name: asideLeftOpen;
		animation-duration: 750ms;
	}

	&.closed {
		display: block;
		animation-name: asideLeftClose;
		animation-duration: 250ms;
		width: 0;
		z-index: 0;
	}
}

@include media-breakpoint-up(md) {
	.aside-left {
		left: $nav-width;
		width: $left-aside-width;
		top: 60px;
		right: unset;
		bottom: 0;
	}
}

$right-aside-width: 500px;

@keyframes asideRightOpen {
	0% {
		transform: translate3d(100%, 0, 0);
		width: $right-aside-width;
	}

	// 50% {
	//   transform: translate3d(0, 0, 0);
	// }

	// 75% {
	//   transform: translate3d(-4%, 0, 0);
	// }

	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes asideRightClose {
	from {
		transform: translate3d(0, 0, 0);
		width: $right-aside-width;
	}

	to {
		transform: translate3d(100%, 0, 0);
		width: $right-aside-width;
	}
}

.aside-right {
	display: none;

	position: fixed;
	background-color: $white;
	font-size: $font-size-base;

	top: 68px;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;

	.card {
		border: none;
		box-shadow: none !important;
	}

	.btn:not(.btn-lg) {
		font-size: inherit;
	}

	&.opened {
		@extend .shadow;

		border-top-left-radius: $border-radius;
		display: block;
		z-index: map-get($z-index-map, 'aside');
		animation-name: asideRightOpen;
		animation-duration: 500ms;
	}

	&.closed {
		display: block;
		border: none;
		animation-name: asideRightClose;
		animation-duration: 250ms;
		width: 0;
	}
}

@include media-breakpoint-up(md) {
	.aside-right {
		width: $right-aside-width;
		top: 0;
		left: unset;
		bottom: 0;

		&.aside-right-fullscreen {
			width: calc(100% - #{$nav-width});
			max-width: calc(
				#{map-get($container-max-widths, 'xxxl')} - #{$nav-width}
			);
		}
	}
}

@include media-breakpoint-up(xxxl) {
	.aside-right {
		right: calc(calc(100vw - #{map-get($grid-breakpoints, 'xxxl')}) / 2);
	}
}

.show-on-hover-container {
	& .show-on-hover-target {
		opacity: 0;
		transition: opacity 100ms ease-out;
	}

	&:hover .show-on-hover-target {
		opacity: 1;
	}
}

.no-wrap {
	flex: 1;
	&,
	& > * {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

form {
	fieldset.card {
		margin-bottom: 2rem;

		legend.card-header {
			float: left;
			border: none;
			padding: 0 0 0.5rem 0;
			margin-bottom: 1rem;
		}
	}

	.form-group {
		&.is-invalid {
			input {
				border-color: $danger;
			}

			.react-select-label {
				background-color: $danger;
				color: $white;
			}

			.select-form-control {
				.react-select__control {
					border-color: $danger;
				}
			}
		}

		.invalid-feedback {
			display: inline;
		}
	}
}

.react-json-view {
	.object-content {
		span {
			font-size: $input-font-size;
		}
	}
}

.border-2 {
	border-width: 2px !important;
}

.custom-popup-area {
	position: absolute;
	right: 0;
	min-width: 400px;
	z-index: map-get($z-index-map, 'dropdown');
	background-color: $input-bg;
}

.mermaid-diagram svg {
	width: 100%;
	max-width: unset !important;
	margin-top: 20px;
}

.switch-ui-button {
	z-index: map-get($z-index-map, 'dropdown');
	background-color: white;
	right: 0;
}

.form-label {
	font-size: 12px;
}

.react-select:not(.is-multi) {
	.react-select__control {
		min-height: 35px !important;
		height: 35px;
	}
}

.form-control:not([rows]):not(.tollfree-numbers-textarea) {
	min-height: 35px;
	height: 35px;
}
.ant-picker-clear {
	display: none;
}

.MuiTable-root {
	p.MuiTypography-root.MuiTypography-body1 {
		max-width: unset;
	}
}

;@import "sass-embedded-legacy-load-done:147";
.multi-data-list {
	@extend .d-flex, .flex-wrap;
	position: relative;
	//position: relative;

	&:not(.multi-data-list-display-mode) {
		@extend .form-control;
		height: auto !important;
		min-height: $input-height;
		width: unset;
	}

	input {
		@extend .flex-fill;
		display: flex;
		flex-basis: auto;
		width: 3em;
		flex-shrink: 1;
		border: none !important;
		outline: none !important;

		&[disabled] {
			background: transparent;
		}
	}

	.multi-data-list-item {
		// position: relative;
		// z-index: 2;
		display: flex;
		border-radius: 2px;
		background-color: rgb(230, 230, 230);
		font-size: 10.2px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin: 2px;

		.multi-data-list-item-action {
			align-items: center;
			border-radius: 2px;
			display: flex;
			padding-left: 4px;
			padding-right: 4px;
			font-size: 11px;
			cursor: pointer;

			&:hover {
				background-color: rgb(255, 189, 173);
				color: rgb(222, 53, 11) !important;
			}
		}
	}

	&.multi-data-list-display-mode {
		.multi-data-list-item {
			background: none;
			font-size: 12px;
		}

		input,
		.multi-data-list-item-action {
			display: none;
		}
	}

	.typeahead {
		min-width: 100%;
		position: fixed;
		z-index: $zindex-dropdown;
		display: none; // none by default, but block on "open" of the menu
		min-width: $dropdown-min-width;
		padding: $dropdown-padding-y $dropdown-padding-x;
		margin: 0; // Override default margin of ul
		@include font-size($dropdown-font-size);
		color: $dropdown-color;
		text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
		list-style: none;
		background-color: $dropdown-bg;
		background-clip: padding-box;
		border: $dropdown-border-width solid $dropdown-border-color;
		@include border-radius($dropdown-border-radius);
		@extend .shadow;

		&.is-open {
			display: block;
		}

		.typeahead-item {
			display: block;
			width: 100%; // For `<button>`s
			padding: $dropdown-item-padding-y $dropdown-item-padding-x;
			clear: both;
			font-weight: $font-weight-normal;
			text-align: inherit; // For `<button>`s
			text-decoration: if($link-decoration == none, null, none);
			white-space: nowrap; // prevent links from randomly breaking onto new lines
			background-color: transparent; // For `<button>`s
			border: 0; // For `<button>`s
			cursor: pointer;

			&:hover,
			&:focus {
				color: $dark;
				text-decoration: if($link-hover-decoration == underline, none, null);
				@include gradient-bg($dropdown-link-hover-bg);
			}

			&.active,
			&:active {
				color: $dropdown-link-active-color;
				text-decoration: none;
				@include gradient-bg($dropdown-link-active-bg);
			}

			&.disabled,
			&:disabled {
				color: $dropdown-link-disabled-color;
				pointer-events: none;
				background-color: transparent;
				// Remove CSS gradients if they're enabled
				background-image: if($enable-gradients, none, null);
			}
		}
	}
}

.multi-data-list-item-label {
	padding: 3px 6px;

	&.multi-data-list-item-label-exact-match {
		@extend .bg-warning, .text-white;
	}
	&.multi-data-list-item-label-parent-match {
		@extend .bg-info, .text-white;
	}
	&.multi-data-list-item-label-child-match {
		background: map-get($table-variants, 'info');
	}
	&.multi-data-list-item-label-invalid {
		@extend .bg-danger, .text-white;
	}
}

;@import "sass-embedded-legacy-load-done:125";
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';

$grid-breakpoints: (
	xxxs: 0,
	xxs: 320px,
	xs: 568px,
	sm: 667px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1440px,
	xxxl: 1600px,
	2xxl: 1800px
);

$container-max-widths: (
	xxxs: 0,
	xxs: 320px,
	xs: 568px,
	sm: 667px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1440px,
	xxxl: 1600px,
	2xxl: 1800px
);

@import '../../node_modules/bootstrap-scss/bootstrap';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '~animate.css/animate.css';
@import '~react-h5-audio-player/lib/styles.css';
@import './browserUpdate.scss';
@import './progress.scss';
@import '~react-toastify/dist/ReactToastify.css';
@import './react-datetime.scss';
@import './mermaid.scss';
@import './alert.scss';
@import './autoHeight.scss';
@import './anchor.scss';
@import './badge.scss';
@import './beautifulNight';
@import './btns.scss';
@import './cards.scss';
@import './drawer.scss';
@import './dashboard.scss';
@import './globalSearch.scss';
@import './flowStep.scss';
@import './animations.scss';
@import './navigation.scss';
@import './dnd.scss';
@import './multiDataList.scss';
@import './modal.scss';
@import './input.scss';
@import '~react-dates/lib/css/_datepicker.css';
@import '~rheostat/css/rheostat.css';
@import './slider.scss';
@import './table.scss';
@import './layout.scss';
@import './tooltip.scss';
@import './react-select.scss';
@import './lists.scss';
@import './tabs.scss';
@import './TollfreeNumbersTextArea.scss';
@import './TimeSection.scss';
@import './charts.scss';
@import './ReactNumericInput.scss';
@import './react-h5-audio-player.scss';
@import './dropdownOnBody.scss';
@import './react-circular-progressbar.scss';
@import './notifications.scss';
@import './UrlInputControl.scss';
@import '~flatpickr/dist/themes/airbnb.css';
@import './DateTimeRange.scss';
@import './mui.scss';
@import './types.scss';
@import './main.scss';
//END OF FILE

;@import "sass-embedded-legacy-load-done:3";
//custom vars
$nav-width: 240px;
$left-aside-width: 400px;
$brand-gradient: linear-gradient(
	135deg,
	darken($primary, 25%) 0,
	darken($primary, 20%) 99%
);
$leftNavBorderColor: $white;
$leftNavListGroupBgColor: $leftNavBorderColor;
$leftNavActiveColor: $body-color;
$topNavHeight: 54px;
$input-height: 38px;

//React-Select
$select-input-bg: $input-bg !default;
$select-input-bg-disabled: $input-bg !default;
$select-input-bg-focus: $input-bg !default;
$select-input-border-radius: $border-radius !default;
$select-input-border-color: $input-border-color !default;
$select-input-border-focus: $input-focus-border-color !default;
$select-input-border-width: $input-btn-border-width !default;
$select-input-box-shadow-focus: $input-btn-focus-box-shadow !default;
$select-text-color: $body-color !default;
$select-input-height: 36px !default;
$select-link-hover-color: $info !default;

$select-option-bg: $input-bg !default;
$select-option-focused-bg: $input-focus-bg !default;
$select-option-selected-bg: $input-focus-bg !default;
$select-option-focused-color: $input-focus-color !default;
$select-option-selected-color: $link-hover-color !default;
$select-padding-horizontal: 13px;

$select-item-color: $select-text-color !default;
$select-item-bg: darken($input-bg, 5%) !default;

//$select-menu-max-height:        325px !default;
//$select-menu-zindex:            2 !default;

$select-item-hover-bg: $input-focus-bg !default;

$select-arrow-color: $body-color !default;
$select-arrow-color-hover: $body-color !default;

$select-clear-color: $body-color !default;

// Fonts
$font-size-base: 0.9rem !default;

$headings-font-weight: 400 !default;
$form-group-margin-bottom: 0.9em !default;

// Tables
$table-border-color: rgba(0, 0, 0, 0.1) !default;

// Forms
$input-border-color: rgba(0, 0, 0, 0.1) !default;

$input-group-addon-bg: $gray-200 !default;

// Navbar
$navbar-dark-color: rgba($white, 0.6) !default;
$navbar-dark-hover-color: $white !default;

$navbar-light-hover-color: $body-color !default;
$navbar-light-active-color: $body-color !default;
$nav-pills-link-active-bg: $link-hover-color !default;

$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba($white, 0.1);

// Badges

$badge-font-weight: normal !default;
$badge-padding-y: 0.6em !default;
$badge-padding-x: 1.2em !default;

// Dropdowns
$dropdown-bg: $input-bg !default;
$dropdown-link-hover-bg: $input-focus-bg !default;
$dropdown-link-color: $white !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-active-bg: $input-focus-bg !default;
$dropdown-link-active-color: $link-hover-color !default;
$dropdown-border-color: $light !default;

// Tooltip
$tooltip-color: $body-bg !default;
$tooltip-opacity: 1 !default;
$tooltip-font-size: $font-size-base !default;
$tooltip-bg: $body-color !default;
$tooltip-padding-y: 0.5rem !default;

.text-body-size {
	font-size: $font-size-base;
}

.bg-brand-gradient {
	background: $brand-gradient !important;
}

$z-index-map: (
	browser_update: 111111,
	datetime: 99999,
	aside_modal: 1023,
	date_picker: 1022,
	contextify: 1022,
	aside: 1021,
	help_container: 1021,
	dropdown: 1010,
	dropdown_on_body: 1005,
	aside_left: 1004,
	help_container_toggle: 1002,
	drawer: 4,
	menuitem: 3,
	navigation: 2,
	dnd_file: 1,
	tooltip: 1
);

;@import "sass-embedded-legacy-load-done:2";
// Body
$body-bg: #f9fbfd !default; // #f1f1f1;
$body-color: $gray-800 !default; //$dark !default;
$link-color: $primary !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

//active
$component-active-bg: $primary !default;
$border-radius: 0.375rem !default;

//input
$input-bg: $white !default;
$input-focus-bg: darken($input-bg, 7%) !default;
$input-color: $gray-700 !default;
$input-border-color: hsl(0, 0%, 80%) !default;
$input-focus-border-color: lighten($component-active-bg, 25%) !default;
$input-focus-color: $input-color !default;
$input-btn-focus-width: 0.2rem !default;
$input-btn-focus-color: rgba($component-active-bg, 0.25) !default;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;
$input-focus-width: $input-btn-focus-width !default;
$border-width: 1px !default;
$input-btn-border-width: $border-width !default;
$form-control-plaintext-bg: lighten($input-focus-bg, 2%);
$collapsible-card-bg: $white;
$collapsible-card-color: $body-color;

//btn
$nav-btn-color: $primary;

//DataGrid
$datagrid-no-data-bg: rgba(0, 0, 0, 0.15);

$box-shadow:
	0 7px 14px 0 rgba(59, 65, 94, 0.1),
	0 3px 6px 0 rgba(0, 0, 0, 0.07);

;@import "sass-embedded-legacy-load-done:1";
.MuiCollapse-root {
	width: 100%;
}

.MuiAutocomplete-root.Cpr-Input {
	.MuiInputBase-root {
		padding: 6px 12px !important;
		gap: 3px;

		.MuiInputBase-input {
			height: 22px;
			box-sizing: border-box;
			padding: 0 !important;
		}
	}
}

.Cpr-Value-Display-Link {
	font-size: 10px !important;
	padding: 0 !important;
}

@-moz-document url-prefix() {
	.MuiTableFooter-root {
		padding-bottom: 2px;
	}
}

;@import "sass-embedded-legacy-load-done:145";
.globalSearch-toolbar {
	@extend .d-flex, .justify-content-between, .align-items-center;
	position: relative;
	padding-left: 1rem;

	span {
		position: absolute;
		left: 1.5rem;
		color: $text-muted;
		z-index: 0;
		user-select: none;
	}

	.form-control {
		padding-left: 2rem;
		width: 250px;
		border: 0;
		height: 35px;
	}
}

;@import "sass-embedded-legacy-load-done:120";
.recharts-wrapper {
	text {
		fill: $body-color;
	}
}

// .svg-container {
// 	display: inline-block;
// 	position: relative;
// 	width: 100%;
// 	padding-bottom: 100%;
// 	vertical-align: top;
// 	overflow: hidden;
// }

// .svg-content {
// 	display: inline-block;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// }

;@import "sass-embedded-legacy-load-done:137";
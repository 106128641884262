#root,
#portals {
	a:not(.nav-link):not([href]):not([tabIndex]) {
		color: $link-color;
		text-decoration: none;
		background-color: transparent; // Remove the gray background on active links in IE 10.

		&:hover {
			color: $link-hover-color;
			text-decoration: $link-hover-decoration;
		}
	}

	a:not([href]):not([tabIndex]),
	a {
		cursor: pointer;
	}
}

;@import "sass-embedded-legacy-load-done:113";
.list-group-flush {
	.list-group-item:first-child {
		border-top: 0;
	}
}

.list-group-sm {
	.list-group-item {
		padding: 0.4rem 0.5rem;
	}
}

.list-group-selectable {
	.list-group-item {
		cursor: pointer;
	}
}

.list-group-transparent {
	.list-group-item {
		background: transparent;
		//color: $white;
	}
}

.list-group-border-0 {
	.list-group-item {
		border: none !important;
	}
}

.list-group-nav-hover {
	.list-group-item:hover {
		//color: $white;
		background: rgba(255, 255, 255, 0.5);
		border-radius: 0.375rem;
	}
}

.list-group.list-group-info-highlight {
	& > .list-group-item.active {
		background-color: $gray-200;
		border-color: rgb(255, 255, 255);
	}
}

.router-breadcrumb > .breadcrumb {
	//background: rgba(255, 255, 255, 0.5);
	//background: $white;
	//border: 1px solid #d3dae4;
	// flex-basis: 100%;
	background: transparent;
	padding: 0;
	margin-bottom: 0;
	//font-size: 1rem;
	font-weight: 600;

	@include media-breakpoint-down(md) {
		& > .breadcrumb-item {
			padding-bottom: 15px;
		}
	}

	& > .breadcrumb-item:last-child {
		color: $body-color;
	}

	.breadcrumb-item + .breadcrumb-item {
		padding-left: 0.65rem;
	}

	.breadcrumb-item + .breadcrumb-item::before {
		@extend .align-self-center;
		font-family: 'Font Awesome 6 Free';
		font-weight: 900;
		font-size: 0.8rem;
		padding-right: 0.65rem;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		line-height: 1;
		content: '\f105'; //fa-angle-right
		float: none;
	}

	a {
		color: $gray-600;
	}
}

.stripped-list {
	dl:nth-of-type(even) {
		background-color: rgba(0, 0, 0, 0.05);
	}
	dl {
		padding: 7px 5px;
		margin-bottom: 0;
		overflow-wrap: anywhere;
	}
}

.list-group.flexy-items-list {
	.futures-list-group-item {
		display: flex;
		justify-content: space-between;
	}
}

.accordion {
	&.accordion-nested {
		padding: 0;

		.accordion-item,
		.accordion-button {
			background: none;
		}

		.accordion-button {
			padding: 0 0.5em;
			cursor: pointer;

			&:hover {
				color: $text-muted;
			}

			&::after {
				background-image: none;

				font-family: 'Font Awesome 6 Free';
				font-weight: 900;
				font-size: 18px;
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				content: '\f078'; // fas-info-circle
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:133";
.rt-url-input-control {
	display: flex;

	.url-protocol-select {
		flex: 1;
		margin-right: 1rem;
	}

	.url-input {
		flex: 3;

		label {
			visibility: hidden;
		}
	}
}

;@import "sass-embedded-legacy-load-done:143";
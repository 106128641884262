$drawer-width: 35vw;
$drawer-line-color: $gray-400;
$drawer-open-z-index: map-get($z-index-map, 'drawer');
$drawer-close-z-index: $drawer-open-z-index - 1;
$nav-height: 68px;

@keyframes drawerRightOpen {
	0% {
		transform: translate3d($drawer-width, 0, 0);
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes drawerRightClose {
	0% {
		width: $drawer-width;
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	100% {
		width: $drawer-width;
		opacity: 1;
		transform: translate3d($drawer-width, 0, 0);
	}
}

.drawer {
	box-shadow: 0 0 5px rgba(199, 199, 199, 0.7);
	border: 1px solid $drawer-line-color;
	position: fixed;
	right: 0;
	top: $nav-height;
	height: calc(100vh - #{$nav-height});
	width: 0;
	opacity: 0;
	background-color: $white;
	color: $body-color;
	z-index: -1;

	.card {
		box-shadow: none;
		border: none;

		.card-header {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}

	&.open {
		z-index: $drawer-open-z-index;
		opacity: 1;
		width: 100vw;
		animation-name: drawerRightOpen;
		animation-duration: 500ms;
	}

	&.close {
		width: 0;
		z-index: $drawer-close-z-index;
		animation-name: drawerRightClose;
		animation-duration: 500ms;
	}

	.drawer-header {
		@extend .card-header;
		@extend .d-flex;
		@extend .justify-content-between;
		@extend .shadow-sm;

		background: $white;
		border-bottom: 1px solid $drawer-line-color;

		.drawer-header-title {
			@extend .h4;
			@extend .mb-0;
		}

		.drawer-header-close {
			@extend .btn;
			@extend .btn-sm;
			@extend .btn-link;
			color: $body-color;
		}

		.drawer-header-close::after {
			font-family: 'Font Awesome 6 Free';
			font-weight: 900;
			font-size: 1rem;
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;
			content: '\f00d';
		}
	}

	.drawer-body {
		@extend .card-body;

		overflow-y: auto;
		height: calc(100vh - #{$topNavHeight});
	}
}

@include media-breakpoint-up(md) {
	.drawer {
		top: 0;
		height: 100vh;

		&.open {
			width: calc(100vw - #{$nav-width});
		}
	}
}

@include media-breakpoint-up(lg) {
	.drawer {
		&.open {
			width: $drawer-width;

			&.drawer-level-2 {
				right: $drawer-width;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:118";
.card {
	// @extend .shadow;
	color: $body-color;
	border-color: #d3dae4;
	//box-shadow: $box-shadow;
	background-color: $white;
	//border: 1px solid rgba(0, 0, 0, 0.7);
	//border: 1px solid rgba(0,0,0,.05);
	//box-shadow: 0 0 2rem 0 rgba(136,152,170,.15);
	//box-shadow: 0 10px 25px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);

	// Show color variants in card and card-body
	// for instance .card-danger or .card-body-danger
	@each $color, $value in $table-variants {
		&.card-#{$color},
		& > .card-body.card-body-#{$color} {
			background: $value;
		}
	}

	& .card {
		border: none;
		box-shadow: none;
	}

	&.card-outline {
		background-color: transparent;
	}

	&.no-outline {
		border: 0;
	}

	& > .card-header {
		//@extend .bg-white;
		background: $white;
		//border-bottom: none;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 0;
		}
	}

	// & > .card-footer {
	// background-color: $white;
	// border-color: #d3dae4;
	// }

	.clickable {
		cursor: pointer;
	}
}

.card {
	.nav-tabs {
		background: $gray-200;
		border-bottom: 0;
	}

	.nav-link {
		font-size: var(--bs-body-font-size);
		//font-weight: 600;
		cursor: pointer;
		color: $text-muted;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		padding: 0.45rem 0.9rem;
		outline: 0 !important;
		box-shadow: none !important;

		&:not(.active) {
			&:hover {
				background: $gray-100;
				color: $dark;
			}
		}

		&.active {
			color: $primary;
			background: $white;
		}
	}
}

.collapsible-card-header {
	background: $collapsible-card-bg;
	color: $collapsible-card-color;
	border-bottom-color: #d3dae4;
	font-weight: 600;
}

.shadow-on-hover {
	transition: box-shadow 250ms ease-out;

	&:hover {
		@extend .shadow;
	}
}

.card {
	hr {
		color: rgb(233 236 239);
		opacity: 1;
		margin: 15px 0;
	}
}

;@import "sass-embedded-legacy-load-done:117";
.react-select {
	border-radius: $border-radius;

	&.hide-container {
		.react-select__control {
			background: transparent;
			border: none;
			min-height: unset;

			.react-select__value-container {
				padding: 0;
			}
		}

		.react-select__indicators,
		.react-select__placeholder {
			display: none;
		}
	}

	.react-select__menu {
		z-index: map-get($z-index-map, 'menuitem');
	}

	.react-select__control {
		border-radius: $border-radius;

		&.react-select__control--is-disabled {
			.react-select__multi-value__remove {
				display: none;
			}
		}

		&.react-select__control--is-focused {
			border-color: $input-focus-border-color;
			box-shadow: $input-btn-focus-box-shadow;
		}
	}

	.react-select__multi-value__label {
		.badge-light {
			background-color: $white;
		}
	}
}

.react-select-label-container {
	white-space: normal;

	//copied from react-selectv3 styles
	.react-select-label {
		display: inline-flex;
		white-space: nowrap;
		background-color: hsl(0, 0%, 90%);
		border-radius: 2px;
		margin: 2px;
		padding: 3px;
		padding-left: 6px;
		font-size: 85%;

		&.is-invalid {
			background-color: $danger;
			color: $white;
		}
	}
}

.was-validated {
	.select-form-control {
		.react-select__control {
			@extend .form-control, .is-valid;
			background: none;
			padding: 0;
			display: flex;
		}

		&.is-invalid {
			.react-select {
				&:focus {
					@extend .form-control, .is-invalid, :focus;
				}

				.react-select__control {
					padding: 0;
					display: flex;
					border-color: $danger;
				}

				.react-select__control--is-focused {
					// hack here to override build-in styles
					outline: none !important;
					@extend .form-control, .is-invalid, :focus;
				}
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:132";
.nav.nav-tabs {
	//overflow-x: auto;
	//overflow-y: hidden;
	flex-wrap: nowrap;
	white-space: nowrap;
	//border-bottom: 0;
	//border-bottom-color: transparent;

	.nav-item {
		min-width: 115px;
		text-align: center;

		.nav-link {
			color: $gray-600;
			cursor: pointer;
			//margin-bottom: 1px;
			border-right: 0;
			border-left: 0;
			border-top: 0;
			border-bottom-width: 1.25px;

			font-weight: 600;
			border-color: #d3dae4;

			&.active,
			&:hover {
				color: $primary;
				border-color: $primary;
			}

			// &.active {
			// }
		}
	}
}

.card > .nav.nav-tabs {
	.nav-item {
		.nav-link {
			background-color: transparent;

			&.active {
				border-bottom: 2px solid $primary;
			}
		}
	}
}

.tab-subtitle {
	@extend .text-muted;
	@extend .mb-1;
}

.tab-title {
	@extend .h4;
	@extend .mb-3;
}

;@import "sass-embedded-legacy-load-done:134";
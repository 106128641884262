.modal-content {
	border: none;
	color: $dark;

	//.modal-header {
	// background-color: $primary;
	// color: $white;
	//}

	.modal-footer {
		background-color: $gray-300;
	}
}

.modal.modal-confirm {
	.modal-body {
		@extend .lead;
	}

	.modal-content {
		@extend .shadow-lg;

		border: 1rem solid $gray-200;
	}

	.modal-dialog {
		margin-top: calc(50vh - 100px);
		max-width: 550px;
	}

	.modal-footer {
		background: $white;
	}
}

.rnd-modal {
	z-index: map-get($z-index-map, 'aside_modal') !important;

	.rnd-modal-header:active {
		cursor: move;
	}
}

;@import "sass-embedded-legacy-load-done:126";
.btn {
	border-radius: $border-radius;
	//background-color: $primary;

	&[disabled] {
		border: $border-width solid transparent;
		cursor: not-allowed;
	}
}

.btn.btn-white {
	//background: $white;
	//border-color: $gray-600;

	&:not([disabled]) {
		&:hover,
		&:active {
			border: $border-width solid transparent;
			background: rgba(0, 0, 0, 0.075);
		}
	}
}

.btn.btn-white-alt {
	background: $white;
	border-color: #d3dae4;

	&:hover,
	&:active,
	&:focus {
		border: $border-width solid transparent;
		background: $gray-200;
	}
}

.btn-warning {
	@extend .text-white;
}

.btn-form {
	background-color: $input-bg;
	color: $input-color;
	border: $border-width solid $input-border-color;

	&.active {
		background-color: $input-focus-bg;
	}
}

.btn-radio {
	//background-color: $input-bg;
	text-align: left;
	color: $input-color;
	border: $border-width solid transparent;
	border-radius: $border-radius !important;
	margin-right: 4px;
	height: $input-height;

	&.active {
		z-index: unset !important;
		border: $border-width solid transparent;
	}

	&:not(:first-child) {
		margin-left: 0 !important;
	}

	&:hover {
		border: $border-width solid $input-border-color;
	}

	@include media-breakpoint-down(md) {
		border: $border-width solid $input-border-color;
	}
}

@include media-breakpoint-up(md) {
	.form-group > .btn-group-radio {
		margin-left: -12px;
	}

	.form-group .btn-group-checkbox {
		margin-left: -12px;
	}
}

.btn-group-radio {
	& > .btn {
		height: auto;
	}
}

.btn-group {
	.btn-block + .btn-block {
		margin-top: 0;
	}

	.btn.btn-white {
		border: $border-width solid transparent;
	}
}

.btn-outline-warning {
	color: $warning;

	&:not(:disabled):not(.disabled) {
		&:hover,
		&:focus,
		&:active {
			color: $white;
			border: $border-width solid transparent;
		}
	}
}

.btn.btn-checkbox {
	padding: 0.15rem 0.25rem;
	font-size: 0.7225rem;
	line-height: 1.2;
}

.btn-group.btn-group-radio:not(.btn-group-radio-append),
.btn-group.btn-group-checkbox {
	flex-wrap: wrap;

	@include media-breakpoint-down(xl) {
		flex-direction: column;
		flex-wrap: nowrap;
		width: 100%;

		& > .btn {
			margin-left: 0;
			margin-bottom: 7px;

			border-bottom-left-radius: $border-radius !important;
			border-bottom-right-radius: $border-radius !important;
			border-top-left-radius: $border-radius !important;
			border-top-right-radius: $border-radius !important;
		}
	}
}

.btn-group.btn-group-radio.btn-group-radio-append {
	@include media-breakpoint-down(xl) {
		flex-direction: column;
		width: 100%;

		& > .btn {
			border-radius: 0;
			margin-left: 0;

			&:first-child {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-top-left-radius: $border-radius;
				border-top-right-radius: $border-radius;
			}

			&:last-child {
				border-bottom-left-radius: $border-radius;
				border-bottom-right-radius: $border-radius;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
	}
}

.btn-hover {
	position: 'relative';
	transition:
		transform 200ms ease-in-out,
		background-color 200ms ease-in-out;

	&:not([disabled]):not(.disabled):hover {
		@extend .shadow;
		transform: translateY(-2px);

		&::before {
			content: ' ';
			position: absolute;
			bottom: -3px;
			width: 100%;
			height: 3px;
		}
	}
}

.btn-submit {
	@extend .btn-primary;
	min-width: 160px;

	&:not(.btn-sm) {
		padding-top: 8px;
		padding-bottom: 8px;
	}
}

#root {
	.dropdown.show > .dropdown-menu,
	.btn-group.show:not(.btn-group-default) > .dropdown-menu,
	.btn-group.show.btn-group-default .dropdown-menu {
		@extend .shadow;

		z-index: map-get($z-index-map, 'dropdown');
		border: 1px solid #d3dae4;
	}
}

;@import "sass-embedded-legacy-load-done:116";
@keyframes subtleSlideInRight {
	from {
		transform: translate3d(5%, 0, 0);
		opacity: 0.7;
		visibility: visible;
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes subtleSlideInLeft {
	from {
		transform: translate3d(-5%, 0, 0);
		opacity: 0.7;
		visibility: visible;
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes subtleSlideInDown {
	from {
		transform: translate3d(0, -5%, 0);
		opacity: 0.7;
		visibility: visible;
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.animate__animated {
	animation-duration: 300ms;
	// animation-fill-mode: both;
}

.subtleSlideInRight {
	@extend .animate__animated;
	animation-name: subtleSlideInRight;
}

.subtleSlideInLeft {
	@extend .animate__animated;
	animation-name: subtleSlideInLeft;
}

.subtleSlideInDown {
	@extend .animate__animated;
	animation-name: subtleSlideInDown;
}

.fadeIn {
	@extend .animate__animated;
	animation-name: fadeIn;
}

.fadeOut {
	@extend .animate__animated;
	animation-duration: 250;
	animation-name: fadeOut;
}

;@import "sass-embedded-legacy-load-done:122";
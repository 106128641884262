.dropdown-on-body-container {
	@extend .card-body, .bg-white, .rounded, .shadow-sm;

	// border-right: 1px solid $input-border-color!important;
	// border-left: 1px solid $input-border-color!important;
	// border-bottom: 1px solid $input-border-color!important;
	border: 1px solid $input-border-color !important;

	z-index: map-get($z-index-map, 'dropdown_on_body');
	position: fixed;
	height: auto;
	display: none;

	&.show {
		display: inline-block;
	}
}

;@import "sass-embedded-legacy-load-done:140";